@import "variables.scss";

@import "color.scss";

@import "./layout/layout.scss";
@import "./gradient/gradient.scss";
@import "./scrollbar/scrollbar.scss";

@import "./jodit/jodit.scss";

html {
    font-size: var(--font-size, initial);
}

body {
    width: 100vw;
    height: 100vh;
    height: 100svh;
    height: 100dvh;
    margin: 0;
    padding: 0;
    overflow: hidden;
    font-family: var(--font-family), -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    line-height: var(--line-height, initial);
    background-color: var(--primary-background-color, inherit);
    color: var(--primary-color, inherit);
    border-color: var(--primary-border-color, inherit);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
body > div#root {
    background-color: inherit;
    color: inherit;
    border-color: inherit;
}

table,
thead,
tbody,
tr,
th,
td {
    background-color: inherit;
    color: inherit;
    border-color: inherit;
}

* {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */
}

#root {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100vw;
    height: 100vh;
    height: 100svh;
    height: 100dvh;
    overflow: hidden;
}

// Customized styling for the cookie consent component.
.freeprivacypolicy-com---pc-overlay {
    overflow: hidden;
}

.freeprivacypolicy-com---nb-interstitial {
    top: 3vh;
    left: 3vh;
    height: auto;
    max-height: calc(100% - 6vh);
    width: calc(100% - 6vh);
}

.freeprivacypolicy-com---pc-dialog {
    height: auto;
    max-height: calc(100% - 3.75rem);
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
}

.intercom-lightweight-app {
    display: none;
}
